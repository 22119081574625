<template>
  <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <WhatsAppButton></WhatsAppButton>
  <div class="ps-page--product" v-if="translationloaded==true">
    <div class="ps-container">
      <div class="ps-page__container">
        <div class="order-success-sec">
          <div class="success-icon">
            <img src="/assets/img/success-icon.png" />
          </div>
          <h2>{{translate('orderSuccess')}}</h2>
          <p>{{translate('thankYouForYourPurchase')}}</p>
          <p>
           {{translate('yourOrderNumberIs')}}
            <span class="blue-text">#{{ transaction_id }}</span>
          </p>
          <div class="success-btn">
            <router-link class="ps-btn clor-green clor-white" :to="`/products`"
              >{{translate('continueShopping')}}</router-link
            >
            <router-link class="ps-btn clor-blue clor-white" :to="`/my-order`"
              >{{translate('viewMyOrders')}}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>

    <!-- <div class="ps-deal-of-day mb-4" v-if="getPopularProducts">
    <div class="ps-container">
      <div class="ps-section__header">
        <div class="ps-block--countdown-deal">
          <div class="ps-block__left">
            <h3>{{translate('youMightBeInterestedIn')}}</h3>
          </div>
        </div>
      </div>

      <div class="ps-section__content">
        <div class="ps-carousel--nav owl-slider">
          <ProductSlider
            :products="getPopularProducts"
            :offerBadge="true"
            :newBadge="true"
            @refreshPage="refreshData"
            :translationloaded="translationloaded"
            :translate="translate"
          ></ProductSlider>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <div class="ps-deal-of-day mb-4" v-if="personalCareProduct">
    <div class="ps-container">
      <div class="ps-section__header">
        <div class="ps-block--countdown-deal">
          <div class="ps-block__left">
            <h3>{{translate('bestSelleterInHelthAndPersonalCare')}}</h3>
          </div>
        </div>
      </div>

      <div class="ps-section__content">
        <div class="ps-carousel--nav owl-slider">
          <ProductSlider
            :products="personalCareProduct"
            :offerBadge="true"
            :newBadge="true"
            @refreshPage="refreshData"
            :translationloaded="translationloaded"
            :translate="translate"
          ></ProductSlider>
        </div>
      </div>
    </div>
  </div> -->



  <!-- <div class="ps-deal-of-day mb-4" v-if="getRecentProducts">
    <div class="ps-container">
      <div class="ps-section__header">
        <div class="ps-block--countdown-deal">
          <div class="ps-block__left">
            <h3>{{translate('inspiredByYourBrowsingHistory')}}</h3>
          </div>
        </div>
      </div>

      <div class="ps-section__content">
        <div class="ps-carousel--nav owl-slider">
          <ProductSlider
            :products="getRecentProducts"
            :offerBadge="true"
            :newBadge="true"
            @refreshPage="refreshData"
            :translationloaded="translationloaded"
             :translate="translate"
          ></ProductSlider>
        </div>
      </div>
    </div>
  </div> -->


</template>
<script>
import Breadcrumb from "@/components/Breadcrumb";
// import ProductSlider from "@/components/ProductSlider";
import Config from "@/config";
import helper from "@/helper/helper";
import WhatsAppButton from "@/components/WhatsAppButton";

import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "OrderSuccess",
   props:[
    'translationloaded',
    'translate'
  ],
  data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },
        {
          path: "/order-success",
          name: "Order Success",
        },
      ],
      transaction_id: "",
      order_id: "",
      guest_id: "",
      cust_id: null,
      admin_api_header: "",
      personalCareProduct: [],
    };
  },
  components: {
    Breadcrumb,
    // ProductSlider,
    WhatsAppButton
  },

  mounted() {
    this.transaction_id = this.$route.query.transaction_id;
    this.order_id=this.$route.query.id;
    this.storeDealoftheDayProducts();
    this.setPopularProduct();
    this.storeRecentViewedProducts();
    let $this = this;
    $this.guest_id = localStorage.getItem("guestId");
    $this.cust_id = localStorage.getItem("custId");
    helper.backtoTop();

    $this.admin_api_header = Config.ADMIN_API_HEADER;
    axios
      .get(
        `${Config.BASE_URL}RelatedProducts?urlKey=after-shave-malizia&custId=${$this.cust_id}&guestId=${$this.guest_id}`,
        $this.admin_api_header
      )
      .then(function(response) {
        $this.personalCareProduct = response.data.Data.RelatedList;
      });

      $this.sendOrderNotification(this.order_id);
      $this.updateRefrenceClick();
  },
  methods: {
    ...mapActions([
      "storeDealoftheDayProducts",
      "setPopularProduct",
      "storeRecentViewedProducts",
      "sendOrderNotification"
    ]),

    updateRefrenceClick(){
       let bid= localStorage.getItem('ref_banner_id');
       let custId = localStorage.getItem('ref_cust_id');

       if(bid!='' && custId!=''){
          axios.post(`${Config.BASE_URL}addAFAnalytics`,{
            'bannerId':bid,
            'anRefrer':custId,
            'custId':this.cust_id,
            'orderid':this.order_id
          }).then(function(){
              localStorage.setItem('ref_banner_id','');
              localStorage.setItem('ref_cust_id','');
            
          });
       }
      
    }
  },
  computed: {
    ...mapGetters(["getPopularProducts", "getRecentProducts"]),
  },
  created() {},
};
</script>
<style soped></style>
